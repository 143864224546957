// copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

// function to convert date sent in API (ISO 8601) to readable date
function formatDateIso(isoString) {
    let options = { weekday: 'long', month: 'long', day: 'numeric' };
    let date = new Date(isoString);
    return date.toLocaleDateString('en-US', options)
}

//function to convert date sent in API call to readable date
function EpochToDate(epoch) {
    if (epoch < 10000000000)
        epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
    var epoch = epoch + (new Date().getTimezoneOffset() * -1); //for timeZone  
    var options = { weekday: 'long', month: 'long', day: 'numeric' };
    return new Date(epoch).toLocaleDateString('en-US', options);
}

async function dynamicLoadJs(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        script.onload = () => resolve(url);
        script.onerror = (error) => reject(`Failed to load JavaScript from ${url}, ${error}`);
        document.head.appendChild(script);
    });
};

module.exports = {
    formatDateIso,
    dynamicLoadJs
}